import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import config from "../config.js";
import { daysOfWeek, genericErrorMsg, shiftOffsetToDate } from "../utils.js";

export default function AddShiftModal(props) {
    
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
        
    const [errorMsg, setErrorMsg] = useState(null);
    const types = props.types;
    const daysOfWeekOps = daysOfWeek.map((t, index)=>{ return {value: index, label: t}});
    const timeIntervals = [...Array(24*4+1).keys()].map(t=>{ return {value: parseInt(t / 4) * 60 * 60 + (((t % 24) * 15) % 60) * 60, label: `${String(parseInt(t / 4)).padStart(2, '0')}:${String(((t % 24) * 15) % 60).padStart(2, '0')}`}})
    const guests = props.guests;

    const [selectedType, setSelectedType] = useState(props.shift ? types.find(t=>t.value==props.shift.type).value : types[0].value);
    const [selectedRegularAssignee, setSelectedRegularAssignee] = useState(props.shift ? guests.find(t=>t.value==props.shift.regularAssigneeId)?.value : null);
    const [startTimeOffset, setStartTimeOffset] = useState(props.shift ? props.shift.startTimeOffset : 0);
    const [endTimeOffset, setEndTimeOffset] = useState(props.shift ? props.shift.endTimeOffset : 0);
    const [selectedTags, setSelectedTags] = useState(props.shift ? props.tags.filter(tag=>props.shift.tags.find(t=>tag.value==t.name)).map(t=>t.value) : []);
    const [allowDoubleBooking, setAllowDoubleBooking] = useState(false);

    const changeStartTimeOffset = (dayOfWeek, time) => {
        if (dayOfWeek==null) dayOfWeek = parseInt(document.getElementsByName('startTimeDayOfWeek')[0].value);
        else changeEndTimeOffset(dayOfWeek, null);
        if (time==null) time = parseInt(document.getElementsByName('startTimeTime')[0].value);
        setStartTimeOffset(dayOfWeek * 24 * 3600 + time);
    }

    const changeEndTimeOffset = (dayOfWeek, time) => {
        console.log('changing end time');
        if (dayOfWeek==null) dayOfWeek = parseInt(document.getElementsByName('endTimeDayOfWeek')[0].value);
        if (time==null) time = parseInt(document.getElementsByName('endTimeTime')[0].value);
        setEndTimeOffset(dayOfWeek * 24 * 3600 + time);
    }

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-shift-form');
        form.classList.add('was-validated');

        if (endTimeOffset<=startTimeOffset) {
            setErrorMsg('End time should be after Start time');
            return false;
        }

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/shifts` + (props.shift?.id ? `/${props.shift.id}`: ''), 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    week: props.week,
                    type: selectedType,
                    startTimeOffset: startTimeOffset,
                    endTimeOffset: endTimeOffset,
                    notes: document.getElementsByName('notes')[0].value,
                    regularAssigneeId: selectedRegularAssignee!='' ? selectedRegularAssignee : null,
                    tags: selectedTags,
                    allowDoubleBooking: allowDoubleBooking
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else {
                setErrorMsg(data.reason);
                if (data.reason.includes('already booked')) {
                    setErrorMsg(data.reason + " Press Save again to book or tap Close to cancel.");
                    setAllowDoubleBooking(true);
                }
            } })            
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-shift-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.shift?.id ? 'Update' : 'Add'} Schedule Config</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-shift-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Type:</label>
                            <Select options={types} defaultValue={()=>types.find(t=>t.value==props.shift?.type) || types[0]} onChange={(type)=>setSelectedType(type.value)}/>
                        </div>
                        <div>
                            <label>Start Time:</label>
                            <div className='d-flex gap-3'>
                                <Select className='w-25' name='startTimeDayOfWeek' options={daysOfWeekOps} defaultValue={()=>props.shift ? daysOfWeekOps.find(t=>t.value==shiftOffsetToDate(startTimeOffset).dayOfWeekIndex) : daysOfWeekOps[0]} onChange={(day) => changeStartTimeOffset(day.value, null)} />
                                <Select className='w-25' name='startTimeTime' options={timeIntervals} defaultValue={()=>props.shift ? timeIntervals.find(t=>t.value==startTimeOffset % (24*3600)) : timeIntervals[0]} onChange={(time) => changeStartTimeOffset(null, time.value)} />
                            </div>
                        </div>                        
                        <div>
                            <label>End Time:</label>
                            <div className='d-flex gap-3'>
                                <Select className='w-25' name='endTimeDayOfWeek' options={daysOfWeekOps} defaultValue={()=>props.shift ? daysOfWeekOps.find(t=>t.value==shiftOffsetToDate(endTimeOffset).dayOfWeekIndex) : daysOfWeekOps[0]} onChange={(day) => changeEndTimeOffset(day.value, null)} />
                                <Select className='w-25' name='endTimeTime' options={timeIntervals} defaultValue={()=>props.shift ? timeIntervals.find(t=>t.value==endTimeOffset % (24*3600)) : timeIntervals[0]} onChange={(time) => changeEndTimeOffset(null, time.value)} />
                            </div>
                        </div>  
                        <div>
                            <label>Notes:</label>
                            <Form.Control type="text" name='notes' defaultValue={props.shift?.notes} />
                        </div>  
                        <div>
                            <label>Tags:</label>
                            <Select options={props.tags} isMulti defaultValue={()=>props.shift ? props.tags.filter(tag=>selectedTags.find(t=>tag.value==t)) : []} onChange={(tags)=>setSelectedTags(tags.map(t=>t.value))}/>
                        </div>                                                                                                                                          
                        <div>
                            <label>Name:</label>
                            <Select options={guests} defaultValue={()=>guests.find(g=>g.value==props.shift?.regularAssigneeId) || guests[0]} onChange={(g)=>{setSelectedRegularAssignee(g.value);}}/>
                        </div>                                                                                                                                          
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}