import dayjs from 'dayjs';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import "react-datepicker/dist/react-datepicker.css";
import { IoAddOutline, IoArrowBackSharp, IoArrowForwardSharp, IoCalendarClearOutline, IoPricetagOutline } from "react-icons/io5";
import { MdContentCopy, MdDelete, MdMode } from "react-icons/md";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import AddScheduleModal from "../components/AddScheduleModal.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import MultiTagModal from "../components/MultiTagModal.js";
import Nav from "../components/Nav.js";
import config from "../config.js";
import { genericErrorMsg, guestToString } from "../utils.js";
import LoadingSpinner from '../components/LoadingSpinner.js';

export default function SchedulePage() {

  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var weekOfYear = require('dayjs/plugin/weekOfYear')
  dayjs.extend(weekOfYear)
  var timezone = require('dayjs/plugin/timezone');
  dayjs.extend(timezone);

  var _ = require('lodash')

  const [shiftTypes, setShiftTypes] = useState([])
  const [types, setTypes] = useState([])

  const [periodStartDate, setPeriodStartDate] = useState(null);
  const [periodEndDate, setPeriodEndDate] = useState(null);
  const [periodId, setPeriodId] = useState(null);

  const [signupPeriods, setSignupPeriods] = useState(null);
  const [schedules, setSchedules] = useState(null);

  const [guests, setGuests] = useState([]);
  const [guestsOptions, setGuestsOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const guestTypeOptions = ['Team', 'Guest'].map(gt=>{ return {value: gt, label: gt }});

  const tagCountFilterOptions = ['< 4 x half shifts or < 2 full shifts', '< 2 small shifts', '< 1 booth shifts'].map((op, i)=>{ return {value: i, label: op }});

  const [showAddScheduleModal, setShowAddScheduleModal] = useState(false);
  const [showDeleteScheduleModal, setShowDeleteScheduleModal] = useState(false);
  const [showSendInvitesModal, setShowSendInvitesModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [selectedTagCountFilters, setSelectedTagCountFilters] = useState([]);
  const [selectedGuestTypes, setSelectedGuestTypes] = useState([]);

  const [selectedAssignees2, setSelectedAssignees2] = useState([]);

  const [canMultiTag, setCanMultiTag] = useState(false);
  const [showMultiTagModal, setShowMultiTagModal] = useState(false);
  const [multiTagDelete, setMultiTagDelete] = useState(false);

  const [creatingFromConfig, setCreatingFromConfig] = useState(false);

  const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };

  const typesByName = useMemo(()=> {
    return _.groupBy(shiftTypes, 'type');
  }, [types]);

  const fetchSignupPeriods = () => {
    fetch(`${config.server_base_url}/api/signupPeriods`)
        .then((response) => response.json())
        .then((data) => {
          setSignupPeriods(data.signupPeriods)

          changePeriod(data.signupPeriods.filter(period=>dayjs().isBefore(period.startDate, 'day'))[0]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }   

  const fetchSchedules = () => {
    fetch(`${config.server_base_url}/api/schedules?startTime=${periodStartDate.unix()}&endTime=${periodEndDate.unix()}`)
        .then((response) => response.json())
        .then((data) => setSchedules(data.schedules))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const fetchShiftTypes = () => {
    fetch(`${config.server_base_url}/api/shiftTypes`)
        .then((response) => response.json())
        .then((data) => {setShiftTypes(data.types); setTypes(data.types.map(t=>{ return {value: t.type, label: t.type}}))})
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }    

  const copySchedulesFromConfig = () => {
    setCreatingFromConfig(true);
    fetch(`${config.server_base_url}/api/copySchedulesFromConfig`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          startTime: periodStartDate.unix(),
          endTime: periodEndDate.unix(), 
        })
      })
      .then((response) => response.json())
      .then((data) => { if (data.success) fetchSchedules(); else toast.error( genericErrorMsg, {theme: 'colored'}); })
      .then(()=> setCreatingFromConfig(false))
      .catch(error => { toast.error( genericErrorMsg, {theme: 'colored'}); setCreatingFromConfig(false)} );
  }  

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
        .then((response) => response.json())
        .then((data) => {
          setGuests(data.guests);
          setGuestsOptions([{value: '', label: 'unassigned'}, ...data.guests.filter(g=>g.isActive).map(g=>{ return {value: g.id, label: `${g.preferredName} ${g.lastName.slice(0,3)}.`}})]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }   

  const fetchTags = () => {
    fetch(`${config.server_base_url}/api/tags`)
        .then((response) => response.json())
        .then((data) => setTags(data.tags.map(tag=>{ return {value: tag.name, label: tag.name}})))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  
  
  const deleteSchedule = (scheduleId) => {
    fetch(`${config.server_base_url}/api/schedules/${scheduleId}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchSchedules() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const sendAllInvites = () => {
    fetch(`${config.server_base_url}/api/signupPeriods/${periodId}/sendAllInvites`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (!data.success) toast.error( genericErrorMsg, {theme: 'colored'}) })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  

  const changePeriod = (signupPeriod) => {
    setPeriodEndDate(dayjs(signupPeriod.endDate).startOf('day'));
    setPeriodStartDate(dayjs(signupPeriod.startDate).startOf('day'));
    setPeriodId(signupPeriod.id);
  }

  const prevPeriod = () => signupPeriods.find(sp=>sp.id==periodId-1)
  const nextPeriod = () => signupPeriods.find(sp=>sp.id==periodId+1)
  
  const schedulesByGuest = useMemo(()=>{
    return _.groupBy(schedules, (schedule)=>schedule.assigneeId)
  }, [schedules])

  const guestsById = useMemo(()=>{
    return _(guests).groupBy(g=>g.id).value(); 
  }, [guests])

  const tagCountForGuest = (guestId) => {
    return _.groupBy(schedulesByGuest[guestId].flatMap(schedule=>schedule.tags), 'name');
  }

  const assigneeMeetsTagCountFilter = (assigneeId) => {
    if (selectedTagCountFilters.length==0) return true;

    if (!schedulesByGuest.hasOwnProperty(assigneeId)) return true; 
    
    const tagCount = tagCountForGuest(assigneeId);

    const criteria1 = !selectedTagCountFilters.includes(0) || ((selectedTagCountFilters.includes(0) && 
      ((tagCount.hasOwnProperty('half_shift') && tagCount['half_shift'].length<4) || 
      (tagCount.hasOwnProperty('full_shift') && tagCount['full_shift'].length<2) ||
      (!tagCount.hasOwnProperty('full_shift') && !tagCount.hasOwnProperty('half_shift')))));

    const criteria2 = !selectedTagCountFilters.includes(1) || ((selectedTagCountFilters.includes(1) && 
      ((tagCount.hasOwnProperty('small_shift') && tagCount['small_shift'].length<2) ||
      (!tagCount.hasOwnProperty('small_shift')))));

    const criteria3 = !selectedTagCountFilters.includes(2) || ((selectedTagCountFilters.includes(2) && 
      ((tagCount.hasOwnProperty('booth') && tagCount['booth'].length<1) ||
      !tagCount.hasOwnProperty('booth'))));

    return criteria1 && criteria2 && criteria3;
  }

  const selectedSchedulesChanged = (chk)=> {
    if (chk.id=='allcheck') {
      const checks = document.querySelectorAll('.schedules-table tbody>tr>td:nth-child(1)>input[type=checkbox]');
      checks.forEach((check)=>check.checked=chk.checked);
    } else {
      const allChecked = document.querySelectorAll('.schedules-table tbody>tr>td:nth-child(1)>input[type=checkbox]:not(:checked)').length==0;
      document.getElementById('allcheck').checked=allChecked;
    }
    setCanMultiTag(document.querySelectorAll('.schedules-table tbody>tr>td:nth-child(1)>input[type=checkbox]:checked').length>0);
  }

  const getCheckedSchedules = () => {
    const checkedIds = Array.from(document.querySelectorAll('.schedules-table tbody>tr>td:nth-child(1)>input[type=checkbox]:checked')).map(chk=>parseInt(chk.id.replace('check', '')));
    return schedules.filter(schedule=>checkedIds.includes(schedule.id));
  }

  useEffect(() => {
    fetchSignupPeriods()
  }, []);
  
  useEffect(() => {
    if (periodStartDate!=null) {
      fetchShiftTypes();
      fetchSchedules();
      fetchGuests();
      fetchTags();
    }
  },[periodStartDate])

  return (
    <div id='app-container' className="app-container">
      <Nav url="/schedules"></Nav>
      {periodStartDate!=null && periodEndDate!=null ?
      <Fragment>
        <div className='page-header'><h1>Schedules</h1><div className='period-filter'><Button variant='link' disabled={prevPeriod()==null} onClick={()=>{changePeriod(prevPeriod())} }><IoArrowBackSharp /></Button><span>{periodStartDate.format('ddd DD MMM')} - {periodEndDate.subtract(1, 'day').format('ddd DD MMM')}</span><Button variant='link' disabled={nextPeriod()==null} onClick={()=>{changePeriod(nextPeriod())} }><IoArrowForwardSharp/></Button></div></div>
        <div id='page-container' className="page-container schedules-page">  
          <Tabs defaultActiveKey="schedules" className="mb-3">
            <Tab eventKey="schedules" title="Schedules">
              <div className='data-table-container'>
                <div className='table-actions table-filters'>
                  <label className='filter-group'>Filter:</label>
                  <div className="type-filter">
                    <Select options={types} value={types.filter(t=>selectedTypes.includes(t.value))} onChange={(tps) => (async ()=>setSelectedTypes(tps.map(t=>t.value)))()} isMulti placeholder="All types" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>
                  <div className="assignee-filter">
                    <Select options={guestsOptions} value={guestsOptions.filter(t=>selectedAssignees.includes(t.value))} onChange={(g) => (async ()=>setSelectedAssignees(g.map(g=>g.value)))()} isMulti placeholder="All names" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>  
                  <div className="tags-filter">
                    <Select options={tags} value={tags.filter(t=>selectedTags.includes(t.value))} onChange={(tps) => (async ()=>setSelectedTags(tps.map(t=>t.value)))()} isMulti placeholder="All tags" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>
                  <div style={{flex: '1 1 0px'}} />
                  {schedules && schedules.length<20 ? <Button variant="primary" disabled={creatingFromConfig} onClick={()=>copySchedulesFromConfig()}>Create from Config {creatingFromConfig ? <LoadingSpinner /> : <Fragment/>}</Button> : <Fragment/>}
                  <Button variant='primary' onClick={()=>{setMultiTagDelete(false); setShowMultiTagModal(true); }} disabled={!canMultiTag}><IoPricetagOutline /> Tag</Button>
                  <Button variant='primary' onClick={()=>{setMultiTagDelete(true); setShowMultiTagModal(true); }} disabled={!canMultiTag}><IoPricetagOutline /> Untag</Button>
                  <Button variant='primary' onClick={()=> {setShowSendInvitesModal(true);}}><IoCalendarClearOutline /> Send Calendar Invites</Button> 
                  <Button variant="primary" onClick={()=>{setSelectedSchedule(null); setShowAddScheduleModal(true); }}><IoAddOutline /> Create</Button>
                </div>
                
                {schedules && schedules.length==0 ? <h3 className='mt-5'>There are no schedules for this period yet. Click <em>Create from Config</em> to start.</h3> : <Fragment />}

                {schedules && schedules.length>0 ?
                  <Table striped bordered hover className='schedules-table data-table'>
                  <thead>
                    <tr>
                      <td><input type='checkbox' id='allcheck' onChange={(e)=>selectedSchedulesChanged(e.target)} /></td>
                      <td className='shift-type'>Type</td>
                      <td>Date</td>
                      <td>Time</td>
                      <td>Tags</td>
                      <td>Name</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {schedules && shiftTypes.length && schedules.filter(schedule=>
                      (selectedTypes.length==0 || selectedTypes.includes(schedule.type)) &&
                      ((selectedAssignees.length==0 || selectedAssignees.includes(schedule.assigneeId)) 
                        || (selectedAssignees.includes('') && schedule.assigneeId==null)) &&
                      (selectedTags.length==0 || !(selectedTags.map(tagName=>schedule.tags.find(tag=>tag.name==tagName)!=null).includes(false)))
                    ).map(schedule=>
                      <tr key={schedule.id}>
                        <td><input type='checkbox' id={`${schedule.id}check`} onChange={(e)=>selectedSchedulesChanged(e.target)} /></td>
                        <td className='shift-type' style={{backgroundColor: typesByName[schedule.type] ? typesByName[schedule.type][0].colour : 'white'}}>{schedule.type}<br/><em className='small'>{schedule.notes}</em></td>
                        <td>{dayjs.unix(schedule.startTime).tz('Europe/Lisbon').format('ddd D MMM')}{schedule.endTime-schedule.startTime>24*3600 ? ` - ${dayjs.unix(schedule.endTime-1).tz('Europe/Lisbon').format('ddd D MMM')}`: ''}</td>
                        <td>{schedule.endTime-schedule.startTime>=24*3600 ? 'any' : `${dayjs.unix(schedule.startTime).tz('Europe/Lisbon').format('HH:mm')}-${dayjs.unix(schedule.endTime).tz('Europe/Lisbon').format('HH:mm')}`}</td>
                        <td>{schedule.tags.map(tag=><span className='bg-secondary text-white p-2 d-inline-block rounded m-1' key={tag.name}>{tag.name}</span>)}</td>
                        <td>{guestToString(schedule.assignee)}{schedule.isAssigneeOffland ? <span style={{color: 'red'}}>OFFLAND</span> : <span></span>}</td>
                        <td className='row-actions'>
                          <Button title="Clone" variant='light' onClick={()=> {setShowAddScheduleModal(true); const clone = { ...schedule }; delete clone.id; setSelectedSchedule(clone); }}><MdContentCopy /></Button>
                          <Button variant='light' onClick={()=> {setShowAddScheduleModal(true); setSelectedSchedule(schedule);}}><MdMode /></Button> 
                          <Button variant='light' onClick={()=>{setShowDeleteScheduleModal(true); setSelectedSchedule(schedule);}}><MdDelete /></Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table> : <Fragment />
                }
              </div>
            </Tab>
            <Tab eventKey="signups" title="Signups">
              <div className='data-table-container'>
                <div className='table-actions table-filters'>
                  <label className='filter-group'>Filter:</label>
                  <div className="assignee-filter">
                    <Select options={guestsOptions} value={guestsOptions.filter(t=>selectedAssignees2.includes(t.value))} onChange={(g) => (async ()=>setSelectedAssignees2(g.map(g=>g.value)))()} isMulti placeholder="All names" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>  
                  <div className="guest-type-filter">
                    <Select options={guestTypeOptions} value={guestTypeOptions.filter(t=>selectedGuestTypes.includes(t.value))} onChange={(g) => (async ()=>setSelectedGuestTypes(g.map(g=>g.value)))()} isMulti placeholder="Team & Guest" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div> 
                  <div className="tag-count-filter">
                    <Select options={tagCountFilterOptions} value={tagCountFilterOptions.filter(t=>selectedTagCountFilters.includes(t.value))} onChange={(g) => (async ()=>setSelectedTagCountFilters(g.map(g=>g.value)))()} isMulti placeholder="Any signup count" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>                  
                </div>
                {guests && guests.length>0 && Object.keys(guestsById).length>0 ?
                  <Table striped bordered hover className='signups-table data-table'>
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Signups</td>
                        <td>Tags</td>
                        <td>Offland Dates</td>
                        <td>Last Extra Shift</td>
                        <td style={{width:200}}>Notes</td>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(guestsById).sort((gId1, gId2)=>guestToString(guestsById[gId1][0]).localeCompare(guestToString(guestsById[gId2][0]))).filter(assigneeId=>guestsById[assigneeId][0].isActive==true && (selectedGuestTypes.length==0 || selectedGuestTypes.includes(guestsById[assigneeId][0].type)) && (selectedAssignees2.length==0 || selectedAssignees2.includes(parseInt(assigneeId))) && assigneeMeetsTagCountFilter(assigneeId)).map(assigneeId=>
                        <tr key={assigneeId}>
                          <td>{guestsById[assigneeId][0]?.preferredName} {guestsById[assigneeId][0]?.lastName.slice(0,3)}.</td>
                          <td>
                            {schedulesByGuest.hasOwnProperty(assigneeId) && schedulesByGuest[assigneeId].map(schedule=>
                              <div key={schedule.id}>
                                {schedule.type} - {dayjs.unix(schedule.startTime).tz('Europe/Lisbon').format('ddd D MMM HH:mm')}
                              </div>
                            )}
                          </td>
                          <td>
                            {schedulesByGuest.hasOwnProperty(assigneeId) && Object.keys(tagCountForGuest(assigneeId)).map(name=>
                              <Fragment key={name}><span className='bg-secondary text-white p-2 d-inline-block rounded m-1'>{name}</span>x{tagCountForGuest(assigneeId)[name].length} </Fragment>
                            )}
                          </td>
                          <td>{guestsById[assigneeId][0]?.offlandDates!='[]' ? JSON.parse(guestsById[assigneeId][0]?.offlandDates).map(od=>
                                <span key={od.id}>
                                  {dayjs(od.startDate).tz('Europe/Lisbon').format('DD MMM')}-{dayjs(od.endDate).tz('Europe/Lisbon').format('DD MMM')}<br/>
                                </span>) : ''}</td>
                          <td>{guestsById[assigneeId][0]?.lastExtraShiftDate ? dayjs(guestsById[assigneeId][0]?.lastExtraShiftDate).format('DD MMM, YYYY') : ''}</td>
                          <td dangerouslySetInnerHTML={{ __html: guestsById[assigneeId][0]?.notes!=null ? guestsById[assigneeId][0]?.notes.replace('\n', '<br/>').slice(0, 256) : '' }} />                                
                        </tr>
                      )}
                    </tbody>
                </Table> : <Fragment />}
              </div>
            </Tab>          
          </Tabs>
        </div>  
      </Fragment> : <div id='page-container' />}
      {showAddScheduleModal && guestsOptions.length>0 ? <AddScheduleModal schedule={selectedSchedule} types={types} tags={tags} guests={guestsOptions} onCancel={()=>setShowAddScheduleModal(false)} onSave={()=>fetchSchedules()} minDate={periodStartDate.toDate()} maxDate={periodEndDate.toDate()} /> : <span></span>}
      {showDeleteScheduleModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedSchedule.type} ${dayjs.unix(selectedSchedule.startTime).tz('Europe/Lisbon').format('ddd D MMM')} ${dayjs.unix(selectedSchedule.startTime).tz('Europe/Lisbon').format('HH:mm')}-${dayjs.unix(selectedSchedule.endTime).tz('Europe/Lisbon').format('HH:mm')} </em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteScheduleModal(false)} onConfirm={()=>deleteSchedule(selectedSchedule.id)} /> : <span></span>}
      {showSendInvitesModal ? <ConfirmationModal title={`Are you sure you want to send all the calendar invites for the ${periodStartDate.format('ddd DD MMM')} - ${periodEndDate.format('ddd DD MMM')} signup period ?`} body="" onCancel={()=>setShowSendInvitesModal(false)} onConfirm={()=>sendAllInvites()} /> : <span></span>}
      {showMultiTagModal ? <MultiTagModal tags={tags} schedules={getCheckedSchedules()} isDelete={multiTagDelete} onCancel={()=>setShowMultiTagModal(false)} onSave={()=>fetchSchedules()} /> : <span></span>}
      <ToastContainer />
    </div>
  )
}