import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import "react-datepicker/dist/react-datepicker.css";
import { MdMode } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import Nav from "../components/Nav.js";
import config from "../config.js";
import { genericErrorMsg, guestToString, isLaundryUser } from "../utils.js";
import LaundrySignupModal from '../components/LaundrySignupModal.js';
import { useSearchParams } from 'react-router-dom';

export default function LaundryPage() {

  const [searchParams, setSearchParams] = useSearchParams();

  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var weekOfYear = require('dayjs/plugin/weekOfYear')
  dayjs.extend(weekOfYear)

  var _ = require('lodash')

  const startDate = dayjs().startOf('day');
  const endDate = dayjs().endOf('week').endOf('day').add(1, 'week').add(1, 'day');

  const [laundrySignups, setLaundrySignups] = useState(null);
  const [dates, setDates] = useState([]);

  const [guestsOptions, setGuestsOptions] = useState([]);

  const [showSignupModal, setShowSignupModal] = useState(false);
  const [selectedSignup, setSelectedSignup] = useState(null);

  const fetchLaundrySignups = () => {
    fetch(`${config.server_base_url}/api/laundrySignups`)
        .then((response) => response.json())
        .then((data) => setLaundrySignups(data.laundrySignups))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
        .then((response) => response.json())
        .then((data) => {
          setGuestsOptions([{value: '', label: 'unassigned'}, ...data.guests.map(g=>{ return {value: g.id, label: `${g.preferredName} ${g.lastName.slice(0,3)}.`}})]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  

  
  useEffect(() => {
    fetchGuests();
    fetchLaundrySignups();
    
    var dates = [];
    let currentDate = searchParams.get('yesterday')!=null || isLaundryUser() ? startDate.subtract(1, 'day') : (searchParams.get('lastweek')!=null ? startDate.subtract(7, 'day') : startDate);
    while (currentDate.isBefore(endDate)) {
      dates.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    setDates(dates);
  },[])

  return (
    <div id='app-container' className="app-container laundry-signup-container">
      {/* <Nav url="/laundry"></Nav> */}
      <div className='page-header'><h1>Laundry Signup</h1></div>
      <div id='page-container' className="page-container laundry-signup-page">  
        {laundrySignups && laundrySignups.length>0 ? 
        <Tabs className="mb-3">
          {dates.length && dates.map(date=>
            laundrySignups.filter(signup=>dayjs(signup.date).isSame(date, 'day')).length> 0 ?
            <Tab eventKey={date.format('ddd D MMM')} key={date.format('ddd D MMM')} title={date.format('ddd D MMM')}>
              <div className='data-table-container'>
                <Table striped bordered hover className='laundry-signups-table data-table'>
                  <thead>
                    <tr>
                      <td>Name</td>
                    </tr>
                  </thead>
                  <tbody>
                    {laundrySignups.filter(signup=>dayjs(signup.date).isSame(date, 'day')).map(signup=> 
                      <tr key={signup.id}>
                        <td>
                          {guestToString(signup.assignee)} {!dayjs(signup.date).isBefore(dayjs()) ? <Button variant='link' style={{paddingLeft: 0}} onClick={()=>{ setSelectedSignup(signup); setShowSignupModal(true)}}>{!signup.assignee ? 'Signup' : <span></span>}{signup.assignee ? <MdMode style={{marginBottom: 4}} /> : <span></span>}</Button> : <span style={{height:20, display: 'inline-block'}}></span>}
                        </td>
                      </tr>)}
                  </tbody>
                </Table>
              </div>
            </Tab> :<Fragment key={date.format('ddd D MMM')} />)}               
        </Tabs> : <Fragment />}
      </div>  
      {showSignupModal && guestsOptions.length>0 ? <LaundrySignupModal signup={selectedSignup} guests={guestsOptions} onCancel={()=>{ setShowSignupModal(false); fetchLaundrySignups(); } } onSave={()=>{toast.info( 'Thank you!', {theme: 'colored'}); fetchLaundrySignups(); }} /> : <span></span>}
      <ToastContainer />
    </div>
  )
}