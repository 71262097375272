import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import config from "../config.js";
import { MdContentCopy, MdDelete, MdMode } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { daysOfWeek, genericErrorMsg, shiftOffsetToDate } from "../utils.js";

export default function LaundrySignupModal(props) {
    
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
        
    const [errorMsg, setErrorMsg] = useState(null);
    const guests = props.guests;

    const [signup, setSignup] = useState(props.signup);
    const [selectedAssignee, setSelectedAssignee] = useState(signup ? guests.find(t=>t.value==signup.assigneeId)?.value : null);
    //const [notes, setNotes] = useState(signup?.notes ? signup.notes : '');

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.signup-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/laundrySignups/${signup.id}/signup`, 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    assigneeId: selectedAssignee!='' ? selectedAssignee : null,
                    //notes: document.getElementsByName('notes')[0].value
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else {
                setErrorMsg(data.reason);
                if (data.reason.includes('already booked')) {
                    setErrorMsg(data.reason + " This laundry slot has just been taken.");
                }
            } })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    const unassign = () => {
        fetch(`${config.server_base_url}/api/laundrySignups/${signup.id}/signup`, 
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                assigneeId: null,
                //notes: document.getElementsByName('notes')[0].value
            })
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else {
            setErrorMsg(data.reason);
        } })
        .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
    }

    useEffect(()=> {

    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='signup-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Laundry Signup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='signup-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <b>{dayjs(signup.date).format('ddd D MMM')}</b>
                        </div>
                        <div>
                            <Select options={guests} defaultValue={()=>guests.find(g=>g.value==signup?.assigneeId) || guests[0]} onChange={(g)=>{setSelectedAssignee(g.value);}}/>
                        </div> 
                        {/* <div>
                            <label>Notes (for example: <i>40 degrees, please dry</i>):</label>
                            <Form.Control as="textarea" name="notes" value={notes} onChange={(v)=>{ setNotes(v.target.value); }} rows={3} maxLength={255} />
                        </div>                                                                                                                                                                */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {signup.assigneeId!=null ? <Button variant="secondary" style={{marginRight:'auto'}} onClick={()=> unassign()}>Unassign</Button> : <Fragment /> }
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal> : <Fragment />
        </Fragment>
    );
}