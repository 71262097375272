import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import config from "../config.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { daysOfWeek, genericErrorMsg, shiftOffsetToDate } from "../utils.js";

export default function AddScheduleModal(props) {
    
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
        
    const [errorMsg, setErrorMsg] = useState(null);
    const types = props.types;
    const guests = props.guests;

    const [selectedType, setSelectedType] = useState(props.schedule ? types.find(t=>t.value==props.schedule.type).value : types[0].value);
    const [selectedAssignee, setSelectedAssignee] = useState(props.schedule ? guests.find(t=>t.value==props.schedule.assigneeId)?.value : null);
    const [startTime, setStartTime] = useState(props.schedule ? props.schedule.startTime : 0);
    const [endTime, setEndTime] = useState(props.schedule ? props.schedule.endTime : 0);
    const [selectedTags, setSelectedTags] = useState(props.schedule ? props.tags.filter(tag=>props.schedule.tags.find(t=>tag.value==t.name)).map(t=>t.value) : []);
    const [allowDoubleBooking, setAllowDoubleBooking] = useState(false);

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.add-schedule-form');
        form.classList.add('was-validated');

        if (endTime<=startTime) {
            setErrorMsg('End time should be after Start time');
            return false;
        }

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/schedules` + (props.schedule?.id ? `/${props.schedule.id}`: ''), 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    type: selectedType,
                    startTime: startTime,
                    endTime: endTime,
                    notes: document.getElementsByName('notes')[0].value,
                    assigneeId: selectedAssignee!='' ? selectedAssignee : null,
                    tags: selectedTags,
                    allowDoubleBooking: allowDoubleBooking
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else {
                setErrorMsg(data.reason);
                if (data.reason.includes('already booked') || data.reason.includes('offland')) {
                    setErrorMsg(data.reason + " Press Save again to book or tap Close to cancel.");
                    setAllowDoubleBooking(true);
                }
            } })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='add-schedule-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.schedule?.id ? 'Update' : 'Add'} Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='add-schedule-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Type:</label>
                            <Select options={types} defaultValue={()=>types.find(t=>t.value==props.schedule?.type) || types[0]} onChange={(type)=>setSelectedType(type.value)} />
                        </div>
                        <div>
                            <label>Start Time:</label>
                            <DatePicker selected={startTime!=0 ? new Date(startTime*1000) : props.minDate} onChange={(date) => setStartTime(date.getTime()/1000)} dateFormat="EEE dd MMM, HH:mm" timeFormat='HH:mm' calendarStartDay={1} minDate={props.minDate} maxDate={props.maxDate} showTimeInput onKeyDown={(e) => { e.preventDefault();}} />
                        </div>                        
                        <div>
                            <label>End Time:</label>
                            <DatePicker selected={endTime!=0 ? new Date(endTime*1000) : props.minDate} onChange={(date) => setEndTime(date.getTime()/1000)} dateFormat="EEE dd MMM, HH:mm" timeFormat='HH:mm' calendarStartDay={1} minDate={props.minDate} maxDate={props.maxDate} showTimeInput onKeyDown={(e) => { e.preventDefault();}} />
                        </div>  
                        <div>
                            <label>Notes:</label>
                            <Form.Control type="text" name='notes' defaultValue={props.schedule?.notes} />
                        </div>  
                        <div>
                            <label>Tags:</label>
                            <Select options={props.tags} isMulti defaultValue={()=>props.schedule ? props.tags.filter(tag=>selectedTags.find(t=>tag.value==t)) : []} onChange={(tags)=>setSelectedTags(tags.map(t=>t.value))}/>
                        </div>                                                                                                                                          
                        <div>
                            <label>Name:</label>
                            <Select options={guests} defaultValue={()=>guests.find(g=>g.value==props.schedule?.assigneeId) || guests[0]} onChange={(g)=>{setSelectedAssignee(g.value);}}/>
                        </div>                                                                                                                                          
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}