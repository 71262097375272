import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../components/ConfirmationModal.js";
import LoadingSpinner from "../components/LoadingSpinner.js";
import Nav from "../components/Nav.js";
import ScheduleSignupTable from "../components/ScheduleSignupTable.js";
import SignupModal from "../components/SignupModal.js";
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";

export default function RotaPage() {

  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var weekOfYear = require('dayjs/plugin/weekOfYear')
  dayjs.extend(weekOfYear)
  var timezone = require('dayjs/plugin/timezone');
  dayjs.extend(timezone);

  var _ = require('lodash')

  const periodStartDate = dayjs().startOf('day');
  const periodEndDate = dayjs().add(6, 'week').endOf('day');

  const [shiftTypes, setShiftTypes] = useState([]);
  const [types, setTypes] = useState([])

  const [schedules, setSchedules] = useState(null);

  const [guests, setGuests] = useState([]);
  const [guestsOptions, setGuestsOptions] = useState([]);

  const [showSignupModal, setShowSignupModal] = useState(false);
  const [showMarkAsDoneModal, setShowMarkAsDoneModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [saving, setSaving] = useState(false);

  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };

  const fetchSchedules = () => {
    fetch(`${config.server_base_url}/api/schedules?startTime=${periodStartDate.unix()}&endTime=${periodEndDate.unix()}`)
        .then((response) => response.json())
        .then((data) => setSchedules(data.schedules.filter(s=>shiftTypes.find(t=>t.type==s.type)!=null )))        
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
        .then((response) => response.json())
        .then((data) => {
          setGuests(data.guests);
          setGuestsOptions([{value: '', label: 'unassigned'}, ...data.guests.filter(g=>g.isActive).map(g=>{ return {value: g.id, label: `${g.preferredName} ${g.lastName.slice(0,3)}.`}})]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  
  
  const fetchShiftTypes = () => {
    fetch(`${config.server_base_url}/api/shiftTypes`)
        .then((response) => response.json())
        .then((data) => data.types.filter(t=>t.isOpenForSignup))
        .then((types) => {setShiftTypes(types); setTypes(types.map(t=>{ return {value: t.type, label: t.type}}))})
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }    

  const markAsDone = (schedule) => {
    setSaving(true);
    fetch(`${config.server_base_url}/api/schedules/${schedule.id}/markAsDone`, 
    {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    })
        .then((response) => response.json())
        .then((data) => { fetchSchedules(); setSaving(false); })
        .catch(error => { toast.error( genericErrorMsg, {theme: 'colored'}); setSaving(false); });
  }   
  
  useEffect(() => {
    fetchShiftTypes();
    fetchGuests();
  },[])

  useEffect(() => {
    if (shiftTypes.length>0) {
      fetchSchedules();
    }
  },[shiftTypes])

  return (
    <div id='app-container' className="app-container">
      <Nav url="/rota"></Nav>
      <div className='page-header'><h1>Rota</h1></div>
      <div id='page-container' className="page-container rota-page">  
        {schedules && schedules.length>0 ? 
        <Tabs className="mb-3">
          {types.length && types.map((type, i)=> 
            <Tab eventKey={type.value} key={type.value} title={type.value}>
              <div className='data-table-container'>
                <div className='table-actions table-filters'>
                  <label className='filter-group'>Filter:</label>
                  <div className="assignee-filter">
                    <Select options={guestsOptions} value={guestsOptions.filter(t=>selectedAssignees.includes(t.value))} onChange={(g) => (async ()=>setSelectedAssignees(g.map(g=>g.value)))()} isMulti placeholder="All names" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>  
                </div>
                {schedules && schedules.filter(schedule=>schedule.type==type.value).length>0 ?
                  <ScheduleSignupTable shiftTypes={shiftTypes} schedules={schedules} selectedAssignees={selectedAssignees} type={type} onEdit={(schedule)=>{setShowSignupModal(true); setSelectedSchedule(schedule);}} markAsDone={true} onMarkAsDone={(schedule)=>{setShowMarkAsDoneModal(true); setSelectedSchedule(schedule);}} canSignup={shiftTypes[i].isOpenForSignup} />
                  : <div className="mt-3">Nothing to do :)</div>}
              </div>
            </Tab>)} 
            {types.length ?
            <Tab eventKey='ALL' key='ALL' title='ALL'>
              <div className='data-table-container'>  
                <div className='table-actions table-filters'>
                  <label className='filter-group'>Filter:</label>
                  <div className="assignee-filter">
                    <Select options={guestsOptions} value={guestsOptions.filter(t=>selectedAssignees.includes(t.value))} onChange={(g) => (async ()=>setSelectedAssignees(g.map(g=>g.value)))()} isMulti placeholder="All names" menuPortalTarget={document.body} styles={selectFilterStyles}/>
                  </div>  
                </div>              
                {schedules && schedules.length>0 ?
                  <ScheduleSignupTable shiftTypes={shiftTypes} schedules={schedules.sort((s1,s2)=>s1.startTime-s2.startTime)} selectedAssignees={selectedAssignees} onEdit={(schedule)=>{setShowSignupModal(true); setSelectedSchedule(schedule);}} markAsDone={true} onMarkAsDone={(schedule)=>{setShowMarkAsDoneModal(true); setSelectedSchedule(schedule);}} />
                  : <div>Nothing to do :)</div>}
              </div>
            </Tab> : <Fragment />}                    
        </Tabs> : <Fragment />}
      </div>  
      {showSignupModal && guestsOptions.length>0 ? <SignupModal schedule={selectedSchedule} guests={guestsOptions} onCancel={()=>setShowSignupModal(false)} onSave={()=>fetchSchedules()} preventReassigning={false} preventUnassigning={true} /> : <span></span>}
      {showMarkAsDoneModal ? <ConfirmationModal title={`Are you sure you want to mark this shift as done ?`} body={`${selectedSchedule.type} ${selectedSchedule.notes} ${dayjs.unix(selectedSchedule.startTime).tz('Europe/Lisbon').format('ddd D MMM')} - ${dayjs.unix(selectedSchedule.endTime).tz('Europe/Lisbon').format('ddd D MMM')}`} onCancel={()=>setShowMarkAsDoneModal(false)} onConfirm={()=>markAsDone(selectedSchedule)} /> : <span></span>}
      <div style={{position: 'fixed', top: 'calc(50% - 20px)', right: 'calc(50% - 20px)'}}>
          {saving ? <LoadingSpinner /> : <Fragment/>}
      </div>
      <ToastContainer />
    </div>
  )
}