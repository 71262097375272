import React, { Fragment, useEffect, useState } from 'react';
import { push as DesktopMenu, slide as MobileMenu } from 'react-burger-menu';
import { useNavigate } from "react-router-dom";
import config from '../config';
import { isAdmin, isSuperAdmin, isUser, isLaundryUser } from '../utils';

function NavItem({label, active, onClick, menuLevel=1}) {
  const className = `l${menuLevel}` + (active ? ' active': '');
  return (<a onClick={onClick} className={className}>{label}</a>)
}

const Menu = (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
      document.body.classList.toggle('menu', window.innerWidth>768)
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;

  return (isMobile ? 
    <MobileMenu {...props} isOpen={false}>{props.children}</MobileMenu> : 
    <DesktopMenu {...props} noOverlay>{props.children}</DesktopMenu>
  );
}

export default function Nav({url}) {

  let navigate = useNavigate();
  const [activeUrl, setActiveUrl] = useState(url);

  function navigateTo(newUrl) {
    navigate(newUrl, { replace: false });
    setActiveUrl(newUrl);
  }
  
  return (
    <Fragment>
      <Menu width={'var(--nav-width)'} pageWrapId={"page-container"} outerContainerId={"app-container"} isOpen disableCloseOnEsc disableAutoFocus noTransition onStateChange={(state) => document.body.classList.toggle('menu', state.isOpen)}>
          <Fragment>
            <a className='logo' onClick={() => navigateTo('/rota')}><img src={`${config.server_base_url}/static/img/seva-logo-white.png`}/> </a>
          </Fragment>
          {isAdmin() || isSuperAdmin() ?
          <Fragment>
            <NavItem label='Config' active={activeUrl==='/config'} onClick={() => navigateTo('/config')} />
            <NavItem label='Schedules' active={activeUrl==='/schedules'} onClick={() => navigateTo('/schedules')} />
          </Fragment> : <Fragment />} 
          <NavItem label='Rota' active={activeUrl==='/rota'} onClick={() => navigateTo('/rota')} />
          <NavItem label='Extra Support' active={activeUrl==='/extra'} onClick={() => navigateTo('/extra')} />
          {/* <NavItem label='Signup' active={activeUrl==='/signup'} onClick={() => navigateTo('/signup')} /> */}
          <NavItem label='Laundry' active={activeUrl==='/laundry'} onClick={() => navigateTo('/laundry')} />
          {isAdmin() || isSuperAdmin() || isUser() || isLaundryUser() ? <a className="l1" href={`${config.server_base_url}/logout`}>Logout</a> : <Fragment />}
      </Menu>
    </Fragment>
  )
}