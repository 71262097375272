import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { BlockPicker } from 'react-color';
import { toast } from 'react-toastify';
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";

export default function AddShiftTypeModal(props) {
    
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    const [colour, setColour] = useState(props.type?.colour);
    const [isOpenForSignup, setIsOpenForSignup] = useState(props.type!=null ? props.type.isOpenForSignup : true);
        
    const [errorMsg, setErrorMsg] = useState(null);

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.type-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/shiftTypes` + (props.type?.type ? `/${props.type.type}`: ''), 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    type: document.getElementsByName('type')[0].value,
                    colour: colour,
                    isOpenForSignup: document.getElementById('isOpenForSignup').checked
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='type-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.type ? 'Update' : 'Add'} Shift Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='type-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Type:</label>
                            <Form.Control type="text" name='type' disabled={props.type!=null} defaultValue={props.type?.type} />
                        </div>  
                        <div>
                            <label>Colour:</label>
                            <div>
                                <BlockPicker
                                    color={ colour }
                                    onChangeComplete={ (color)=>setColour(color.hex) }   />
                            </div>
                        </div> 
                        <div>
                            <label>Open for Signup:</label>
                            <Form.Check type='checkbox' name='isOpenForSignup' id='isOpenForSignup' defaultChecked={isOpenForSignup} />
                        </div>                          
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}