import React from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import config from "../config.js";

export default function LoginPage() {

    return (
        <div id='app-container' className="app-container login-app">
            <div id='page-container' className="page-container login-page">  
                <div className='login-box'>
                    <img src={`${config.server_base_url}/static/img/seva-logo-black.png`}/>
                    <a className='social-sign-in google' href={`${config.server_base_url}/auth/google`}><span>Login with Google</span></a>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
  }