const getCookie = (key)=> {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

const utils = {
  daysOfWeek: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon'],
  //shiftTypes: ['BOOTH', 'BREAKFAST', 'DISHWASHING', 'HOUSEKEEPING', 'KITCHEN', 'FOOD DELIVERY' ],
  genericErrorMsg: 'Whoops, something went wrong! Try again later or contact IT.',
  guestToString: (guest) => guest ? `${guest.preferredName} ${guest.lastName.slice(0, 3)}.` : '',

  isSuperAdmin: () => getCookie('theme')=='SUPERADMIN' ||  getCookie('theme')=='SUPER_ADMIN',
  isAdmin: () => getCookie('theme')=='ADMIN',
  isUser: () => getCookie('theme')=='USER',
  isLaundryUser: () => getCookie('theme')=='LAUNDRY',

  shiftOffsetToDate: (offset, endTime = false) => {
    var hours = parseInt((offset % (24 * 3600)) / 3600);
    var minutes = parseInt(((offset % (24 * 3600)) % 3600) / 60);
    var dayOfWeekIndex = parseInt(offset / (24 * 3600));
    if (endTime && hours==0 && minutes==0 && dayOfWeekIndex>0) {
      dayOfWeekIndex--;
      hours = 24;
    }
    return {
      dayOfWeekIndex: dayOfWeekIndex,
      hours: hours,
      minutes: minutes,
    }
  }
}

module.exports = utils