import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import config from "../config.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { daysOfWeek, genericErrorMsg, shiftOffsetToDate } from "../utils.js";

export default function SignupModal(props) {
    
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);
        
    const [errorMsg, setErrorMsg] = useState(null);
    const guests = props.preventUnassigning && props.schedule.assigneeId!=null ? props.guests.slice(1) : props.guests;

    const [selectedAssignee, setSelectedAssignee] = useState(props.schedule ? guests.find(t=>t.value==props.schedule.assigneeId)?.value : null);
    const [allowDoubleBooking, setAllowDoubleBooking] = useState(false);

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.signup-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/schedules/${props.schedule.id}/signup`, 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    assigneeId: selectedAssignee!='' ? selectedAssignee : null,
                    allowDoubleBooking: allowDoubleBooking,
                    preventReassigning: props.preventReassigning
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else {
                setErrorMsg(data.reason);
                if (data.reason.includes('already booked')) {
                    setErrorMsg(data.reason + " Press Save again to book or tap Close to cancel.");
                    setAllowDoubleBooking(true);
                }
            } })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='signup-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Signup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='signup-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            {props.schedule.endTime-props.schedule.startTime>=24*3600 ?
                                (props.schedule.endTime-props.schedule.startTime==24*3600 ?
                                <strong>{props.schedule.type} - {dayjs.unix(props.schedule.startTime).format('ddd D MMM')}</strong> :
                                <strong>{props.schedule.type} - {dayjs.unix(props.schedule.startTime).format('ddd D MMM')} - {dayjs.unix(props.schedule.endTime-1).format('ddd D MMM')}</strong>
                                ) :
                                <strong>{props.schedule.type} - {dayjs.unix(props.schedule.startTime).format('ddd D MMM HH:mm')} - {dayjs.unix(props.schedule.endTime).format('HH:mm')}</strong>
                            }
                        </div>
                        <div>
                            <Select options={guests} defaultValue={()=>guests.find(g=>g.value==props.schedule?.assigneeId) || guests[0]} onChange={(g)=>{setSelectedAssignee(g.value);}}/>
                        </div>                                                                                                                                          
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}