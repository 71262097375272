import dayjs from 'dayjs';
import React, { useMemo } from "react";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import "react-datepicker/dist/react-datepicker.css";
import { MdMode } from "react-icons/md";
import { guestToString } from "../utils.js";
import { Fragment, useEffect, useState } from "react";

export default function ScheduleSignupTable(props) {

    var utc = require('dayjs/plugin/utc')
    var timezone = require('dayjs/plugin/timezone')

    dayjs.extend(utc)
    dayjs.extend(timezone)
    
    const canSignup = props.canSignup !=null ? props.canSignup : true;
    const schedules = props.schedules;
    const selectedAssignees = props.selectedAssignees;
    const type = props.type;

    const typesByName = useMemo(()=> {
      return _.groupBy(props.shiftTypes, 'type');
    }, []);

    return (
        <Table striped bordered hover className='signups-table data-table'>
          <thead>
            <tr>
              <td className='shift-type'>Type</td>
              <td style={{minWidth: 105}}>Date</td>
              <td>Time</td>
              <td>Name</td>
            </tr>
          </thead>
          <tbody>
            {schedules && schedules.filter(schedule=>type==null || schedule.type==type.value).filter(schedule=>
              ((selectedAssignees.length==0 || selectedAssignees.includes(schedule.assigneeId)) 
                || (selectedAssignees.includes('') && schedule.assigneeId==null))
            ).map(schedule=>
              <tr key={schedule.id} className={dayjs.unix(schedule.startTime).isSame(dayjs(), 'day') ? 'today' : ''}>
                <td className='shift-type' style={{backgroundColor: typesByName[schedule.type] ? typesByName[schedule.type][0].colour : 'white'}}>{schedule.type}<br/><em className='small'>{schedule.notes}</em></td>
                <td>{dayjs.unix(schedule.startTime).tz('Europe/Lisbon').format('ddd D MMM')}{schedule.endTime-schedule.startTime>24*3600 ? <Fragment> -<br/>{`${dayjs.unix(schedule.endTime % (24*3600)==0 ? schedule.endTime-1 : schedule.endTime).tz('Europe/Lisbon').format('ddd D MMM')}`}</Fragment>: ''}</td>
                <td>{schedule.endTime-schedule.startTime>=24*3600 ? 'any' : `${dayjs.unix(schedule.startTime).tz('Europe/Lisbon').format('HH:mm')}-${dayjs.unix(schedule.endTime).tz('Europe/Lisbon').format('HH:mm')}`}</td>
                <td>{guestToString(schedule.assignee)} {canSignup ? <Fragment><Button variant='link' style={{paddingLeft: 0}} onClick={()=>props.onEdit(schedule)}>{!schedule.assignee ? 'Signup' : <span></span>}{schedule.assignee ? <MdMode style={{marginBottom: 4}} /> : <span></span>}</Button></Fragment> : <Fragment/>}
                    {props.markAsDone && !schedule.isDone && schedule.assignee ? <Button variant='link' style={{textWrap: 'nowrap', display: 'block', paddingLeft: 0}} onClick={()=>props.onMarkAsDone(schedule)}>mark as done</Button> : <Fragment />}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
    )
}