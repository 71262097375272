import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { toast } from 'react-toastify';
import config from "../config.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { daysOfWeek, genericErrorMsg, shiftOffsetToDate } from "../utils.js";

export default function MultiTagModal(props) {

    const [selectedTags, setSelectedTags] = useState([]);
        
    const [errorMsg, setErrorMsg] = useState(null);
    const schedules = props.schedules;
    const tags = props.tags;

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.multi-tag-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {
            fetch(`${config.server_base_url}/api/schedules/tag`, 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    schedules: schedules.map(schedule=>schedule.id),
                    tags: selectedTags,
                    isDelete: props.isDelete
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason); })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='multi-tag-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.isDelete ? 'Untag' : 'Tag'} {schedules.length} schedules</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='multi-tag-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Tags:</label>
                            <Select options={tags} isMulti required onChange={(tags)=>setSelectedTags(tags.map(t=>t.value))}/>
                        </div>                                                                                                                                          
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}