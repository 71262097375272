import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";

export default function EditGuestModal(props) {
    
    var utc = require('dayjs/plugin/utc');
    dayjs.extend(utc);

    const [lastExtraShift, setLastExtraShift] = useState(props.guest?.lastExtraShiftDate ? dayjs.utc(props.guest?.lastExtraShiftDate).toDate() : null);
        
    const [errorMsg, setErrorMsg] = useState(null);

    const save = (e) => {
        e.preventDefault();
        const form = document.querySelector('form.edit-guest-form');
        form.classList.add('was-validated');

        if (form.checkValidity()===true) {

            fetch(`${config.server_base_url}/api/guests` + (props.guest ? `/${props.guest.id}` : ''), 
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    preferredName: document.getElementsByName('preferredName')[0].value,
                    lastName: document.getElementsByName('lastName')[0].value,
                    email: document.getElementsByName('email')[0].value,
                    isActive: document.getElementById('isActive').checked,
                    receiveCalendarInvites: document.getElementById('receiveCalendarInvites').checked,
                    lastExtraShiftDate: lastExtraShift ? dayjs(lastExtraShift).format('YYYY-MM-DD'): null,
                    notes: document.getElementsByName('notes')[0].value
                })
            })
            .then((response) => response.json())
            .then((data) => { if (data.success) {props.onSave(); props.onCancel();} else setErrorMsg(data.reason) })
            .catch((error) => toast.error( genericErrorMsg, {theme: 'colored'}));
        }
        return false;
    }

    useEffect(()=> {
    }, []);

    return (
        <Fragment>
            <Modal show={true} onHide={props.onCancel} className='edit-guest-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>{props.guest ? 'Edit' : 'Add'} Guest</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate onSubmit={save} className='edit-guest-form'>
                        {errorMsg ? <p className='error'>{errorMsg}</p> : <span></span>}
                        <div>
                            <label>Preferred Name:</label>
                            <Form.Control type="text" name='preferredName' defaultValue={props.guest?.preferredName} disabled={props.guest!=null} />
                        </div>                         
                        <div>
                            <label>Last Name:</label>
                            <Form.Control type="text" name='lastName' defaultValue={props.guest?.lastName} disabled={props.guest!=null} />
                        </div>                         
                        <div>
                            <label>Email:</label>
                            <Form.Control type="text" name='email' defaultValue={props.guest?.email} />
                        </div>  
                        <div>
                            <label>Active:</label>
                            <Form.Check type='checkbox' name='isActive' id='isActive' defaultChecked={props.guest?.isActive} />
                        </div>  
                        <div>
                            <label>Receive calendar invites:</label>
                            <Form.Check type='checkbox' name='receiveCalendarInvites' id='receiveCalendarInvites' defaultChecked={props.guest?.receiveCalendarInvites} />
                        </div>    
                        <div>
                            <label>Last Extra Shift Date:</label>
                            <DatePicker selected={lastExtraShift} onChange={(date) => setLastExtraShift(date)} dateFormat="EEE dd MMM, yyyy" calendarStartDay={1} onKeyDown={(e) => { e.preventDefault();}} />
                        </div>
                        <div>
                            <label>Notes:</label>
                            <Form.Control as="textarea" name="notes" defaultValue={props.guest?.notes} rows={4} maxLength={255} />
                        </div>                                               
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.onCancel}>Close</Button>
                    <Button variant="primary" onClick={save}>Save</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}