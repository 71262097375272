import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import Nav from "../components/Nav.js";
import ScheduleSignupTable from "../components/ScheduleSignupTable.js";
import SignupModal from "../components/SignupModal.js";
import config from "../config.js";
import { genericErrorMsg } from "../utils.js";

export default function ExtraSupportPage() {

  var utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  var weekOfYear = require('dayjs/plugin/weekOfYear')
  dayjs.extend(weekOfYear)

  var _ = require('lodash')

  const periodStartDate = dayjs().startOf('day');
  const periodEndDate = dayjs().add(30, 'day').endOf('day');

  const [shiftTypes, setShiftTypes] = useState([]);
  const [types, setTypes] = useState([])

  const [schedules, setSchedules] = useState(null);

  const [guests, setGuests] = useState([]);
  const [guestsOptions, setGuestsOptions] = useState([]);

  const [showSignupModal, setShowSignupModal] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const fetchSchedules = () => {
    fetch(`${config.server_base_url}/api/schedules?startTime=${periodStartDate.unix()}&endTime=${periodEndDate.unix()}&tag=extra`)
        .then((response) => response.json())
        .then((data) => setSchedules(data.schedules.filter(s=>s.assigneeId==null && shiftTypes.find(t=>t.type==s.type)!=null )))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
        .then((response) => response.json())
        .then((data) => {
          setGuests(data.guests);
          setGuestsOptions([{value: '', label: 'unassigned'}, ...data.guests.filter(g=>g.isActive).map(g=>{ return {value: g.id, label: `${g.preferredName} ${g.lastName.slice(0,3)}.`}})]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  
  
  const fetchShiftTypes = () => {
    fetch(`${config.server_base_url}/api/shiftTypes`)
        .then((response) => response.json())
        .then((data) => data.types.filter(t=>t.isOpenForSignup))
        .then((types) => {setShiftTypes(types); setTypes(types.map(t=>{ return {value: t.type, label: t.type}})) })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }    
  

  useEffect(() => {
    fetchShiftTypes();
    fetchGuests();
  },[])

  useEffect(() => {
    if (shiftTypes.length>0) {
      fetchSchedules();
    }
  },[shiftTypes])

  return (
    <div id='app-container' className="app-container">
      <Nav url="/extra"></Nav>
      <div className='page-header'><h1>Extra Support</h1></div>
      <div id='page-container' className="page-container extra-support-page">  
        {schedules && schedules.length>0 ? 
        <Tabs className="mb-3">
          {types.length ?
            <Tab eventKey='ALL' key='ALL' title='ALL'>
              <div className='data-table-container'>                
                {schedules && schedules.length>0 ?
                  <ScheduleSignupTable shiftTypes={shiftTypes} schedules={schedules.sort((s1,s2)=>s1.startTime-s2.startTime)} selectedAssignees={selectedAssignees} onEdit={(schedule)=>{setShowSignupModal(true); setSelectedSchedule(schedule);}} />
                  : <div>Nothing to do :)</div>}
              </div>
            </Tab> : <Fragment />}          
          {types.length && types.map(type=> 
            <Tab eventKey={type.value} key={type.value} title={type.value}>
              <div className='data-table-container'>                
                {schedules && schedules.filter(schedule=>schedule.type==type.value).length>0 ?
                  <ScheduleSignupTable shiftTypes={shiftTypes} schedules={schedules} selectedAssignees={selectedAssignees} type={type} onEdit={(schedule)=>{setShowSignupModal(true); setSelectedSchedule(schedule);}} />
                  : <div>Nothing to do :)</div>}
              </div>
            </Tab>)}                   
        </Tabs> : <Fragment />}
        {schedules && schedules.length==0 ? <div>Nothing to do :)</div> : <Fragment />}
      </div>  
      {showSignupModal && guestsOptions.length>0 ? <SignupModal schedule={selectedSchedule} guests={guestsOptions} onCancel={()=>setShowSignupModal(false)} onSave={()=>fetchSchedules()} preventReassigning={true} /> : <span></span>}
      <ToastContainer />
    </div>
  )
}