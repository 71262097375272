import React, { Fragment, useEffect, useMemo, useState } from "react";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { IoInformationCircleOutline } from "react-icons/io5";
import Popover from 'react-bootstrap/Popover';
import dayjs from 'dayjs';
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import "react-datepicker/dist/react-datepicker.css";
import { IoAddOutline, IoRefreshOutline, IoCheckmarkSharp } from "react-icons/io5";
import { MdContentCopy, MdDelete, MdMode } from "react-icons/md";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import AddShiftModal from "../components/AddShiftModal.js";
import ConfirmationModal from "../components/ConfirmationModal.js";
import Nav from "../components/Nav.js";
import config from "../config.js";
import { daysOfWeek, genericErrorMsg, shiftOffsetToDate, guestToString } from "../utils.js";
import EditGuestModal from "../components/EditGuestModal.js";
import AddShiftTypeModal from "../components/AddShiftTypeModal.js";
import _ from "lodash";

export default function ConfigPage() {

  const [shifts, setShifts] = useState([]);
  const [guests, setGuests] = useState([]);
  const [guestsOptions, setGuestsOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [signupPeriods, setSignupPeriods] = useState([]);

  const [selectedWeek, setSelectedWeek] = useState(1);
  const [selectedShift, setSelectedShift] = useState(null);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [selectedShiftType, setSelectedShiftType] = useState(null);
  const [showAddShiftModal, setShowAddShiftModal] = useState(false);
  const [showDeleteShiftModal, setShowDeleteShiftModal] = useState(false);
  const [showEditGuestModal, setShowEditGuestModal] = useState(false);
  const [showAddShiftTypeModal, setShowAddShiftTypeModal] = useState(false);
  const [showDeleteShiftTypeModal, setShowDeleteShiftTypeModal] = useState(false);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const infoTooltip = (
    <Popover style={{padding: 10, minWidth: 300}}>
      - Only shifts tagged as <strong>team</strong> will be shown in the main signup.<br/>
      - Shifts tagged as <strong>extra</strong> will be excluded from the main signup and be shown on the Extra Support page.
    </Popover>
  );
  const fetchShifts = () => {
    fetch(`${config.server_base_url}/api/shifts`)
        .then((response) => response.json())
        .then((data) => setShifts(data.shifts))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const typesByName = useMemo(()=> {
    return _.groupBy(types, 'type');
  }, [types]);

  const fetchShiftTypes = () => {
    fetch(`${config.server_base_url}/api/shiftTypes`)
        .then((response) => response.json())
        .then((data) => {
          setTypes(data.types)
          setTypesOptions(data.types.map(t=>{ return {value: t.type, label: t.type}}))
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }  
  
  const fetchSignupPeriods = () => {
    fetch(`${config.server_base_url}/api/signupPeriods`)
        .then((response) => response.json())
        .then((data) => {
          setSignupPeriods(data.signupPeriods)
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }   

  const fetchGuests = () => {
    fetch(`${config.server_base_url}/api/guests`)
        .then((response) => response.json())
        .then((data) => {
          setGuests(data.guests);
          setGuestsOptions([{value: '', label: 'unassigned'}, ...data.guests.filter(g=>g.isActive).map(g=>{ return {value: g.id, label: `${g.preferredName} ${g.lastName.slice(0,3)}.`}})]);
        })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const fetchTags = () => {
    fetch(`${config.server_base_url}/api/tags`)
        .then((response) => response.json())
        .then((data) => setTags(data.tags.map(tag=>{ return {value: tag.name, label: tag.name}})))
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }   

  const deleteShift = (shiftId) => {
    fetch(`${config.server_base_url}/api/shifts/${shiftId}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchShifts() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }   

  const deleteShiftType = (shiftType) => {
    fetch(`${config.server_base_url}/api/shiftTypes/${shiftType}`, 
        {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchShiftTypes() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }   

  const fetchGuestsFromNivas = () => {
    fetch(`${config.server_base_url}/api/guests/fetchFromNivas`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchGuests() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  } 

  const copyShiftsWeekToWeek = (fromWeek, toWeek) => {
    fetch(`${config.server_base_url}/api/copyShiftsFromWeekToWeek`, 
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            fromWeek: fromWeek,
            toWeek: toWeek,
          })
        })
        .then((response) => response.json())
        .then((data) => { if (data.success) fetchShifts() })
        .catch(error => toast.error( genericErrorMsg, {theme: 'colored'}));
  }
  
  const selectFilterStyles = { menuPortal: (base) => ({ ...base, fontSize: '14px', zIndex: 6 }), control: (base) => ({ ...base, fontSize: '14px', paddingTop: 0 }) };
  
  const shiftToString = (shift) => {
    const startDate = shiftOffsetToDate(shift.startTimeOffset);
    const endDate = shiftOffsetToDate(shift.endTimeOffset, true);

    return `${daysOfWeek[startDate.dayOfWeekIndex]} ${String(startDate.hours).padStart(2, '0')}:${String(startDate.minutes).padStart(2, '0')} - ${startDate.dayOfWeekIndex!=endDate.dayOfWeekIndex ? daysOfWeek[endDate.dayOfWeekIndex] : ''} ${String(endDate.hours).padStart(2, '0')}:${String(endDate.minutes).padStart(2, '0')}`; 
  }
  
  useEffect(() => {
    fetchShiftTypes();
    fetchGuests();
    fetchShifts();
    fetchTags();
    fetchSignupPeriods();
  },[])

  function PeopleConfig(props) {
    return (
      <div className='data-table-container'>
        <div className='table-actions table-filters'>
          <div style={{flex: '1 1 0px'}} />
          <Button variant="primary" onClick={()=>fetchGuestsFromNivas()}><IoRefreshOutline /> Fetch from Nivas</Button>
          <Button variant="primary" onClick={()=>{setSelectedGuest(null); setShowEditGuestModal(true); }}><IoAddOutline /> Create</Button>
        </div>
        <Table striped bordered hover className='guests-table data-table'>
          <thead>
            <tr>
              <td>Name</td>
              <td>Type</td>
              <td>Email</td>
              <td>Calendar Invites?</td>
              <td>Active</td>
              <td>Offland Dates</td>
              <td>Last Extra Shift</td>
              <td style={{width:200}}>Notes</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
              {guests && guests.map(guest=>
                <tr key={guest.id}>
                  <td>{guestToString(guest)}</td>
                  <td>{guest.type}</td>
                  <td>{guest.email}</td>
                  <td>{guest.receiveCalendarInvites == true ? <IoCheckmarkSharp/> : ''}</td>
                  <td>{guest.isActive == true ? <IoCheckmarkSharp/> : ''}</td>
                  <td>{guest.offlandDates!='[]' ? JSON.parse(guest.offlandDates).map(od=>
                    <span key={od.id}>
                      {dayjs(od.startDate).format('DD MMM')}-{dayjs(od.endDate).format('DD MMM')}
                    </span>) : ''}</td>
                  <td>{guest.lastExtraShiftDate ? dayjs(guest.lastExtraShiftDate).format('DD MMM, YYYY') : ''}</td>
                  <td dangerouslySetInnerHTML={{ __html: guest.notes!=null ? guest.notes.replace('\n', '<br/>').slice(0, 256) : '' }} />
                  <td className='row-actions'>
                    <Button variant='light' onClick={()=> {setShowEditGuestModal(true); setSelectedGuest(guest);}}><MdMode /></Button> 
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </div>
    )
  }

  function WeekConfig(props) {
    return (
      <div className='data-table-container'>
        <div className='table-actions table-filters'>
          <label className='filter-group'>Filter shifts:</label>
          <div className="type-filter">
            <Select options={typesOptions} value={typesOptions.filter(t=>selectedTypes.includes(t.value))} onChange={(tps) => (async ()=>setSelectedTypes(tps.map(t=>t.value)))()} isMulti placeholder="All types" menuPortalTarget={document.body} styles={selectFilterStyles}/>
          </div>
          <div className="assignee-filter">
            <Select options={guestsOptions} value={guestsOptions.filter(t=>selectedAssignees.includes(t.value))} onChange={(g) => (async ()=>setSelectedAssignees(g.map(g=>g.value)))()} isMulti placeholder="All names" menuPortalTarget={document.body} styles={selectFilterStyles}/>
          </div>  
          <div className="tags-filter">
            <Select options={tags} value={tags.filter(t=>selectedTags.includes(t.value))} onChange={(tps) => (async ()=>setSelectedTags(tps.map(t=>t.value)))()} isMulti placeholder="All tags" menuPortalTarget={document.body} styles={selectFilterStyles}/>
          </div>
          <OverlayTrigger placement='bottom' overlay={infoTooltip}><div><IoInformationCircleOutline style={{fontSize: 24}} /></div></OverlayTrigger>
          <div style={{flex: '1 1 0px'}} />
          {props.week==3 && shifts && shifts.length>0 && shifts.filter(s=>s.week==3).length==0 ? <Button variant='primary' onClick={()=>copyShiftsWeekToWeek(1, 3)}>Copy from Week 1</Button> : <Fragment />}
          {props.week==4 && shifts && shifts.length>0 && shifts.filter(s=>s.week==4).length==0 ? <Button variant='primary' onClick={()=>copyShiftsWeekToWeek(2, 4)}>Copy from Week 2</Button> : <Fragment />}
          <Button variant="primary" onClick={()=>{setSelectedShift(null); setSelectedWeek(props.week); setShowAddShiftModal(true); }}><IoAddOutline /> Create</Button>
        </div>
        <Table striped bordered hover className='shifts-table data-table'>
          <thead>
            <tr>
              <td className='shift-type'>Type</td>
              <td className='shift-time'>Shift</td>
              <td>Tags</td>
              <td>Name</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {shifts && shifts.filter(shift=>
              (shift.week==props.week) &&
              (selectedTypes.length==0 || selectedTypes.includes(shift.type)) &&
              ((selectedAssignees.length==0 || selectedAssignees.includes(shift.regularAssigneeId)) 
                || (selectedAssignees.includes('') && shift.regularAssigneeId==null)) &&
              (selectedTags.length==0 || !(selectedTags.map(tagName=>shift.tags.find(tag=>tag.name==tagName)!=null).includes(false)))
            ).map(shift=>
              <tr key={shift.id}>
                <td className='shift-type' style={{backgroundColor: typesByName[shift.type] ? typesByName[shift.type][0].colour : 'white'}}>{shift.type}<br/><em className='small'>{shift.notes}</em></td>
                <td className='shift-time'>{shiftToString(shift)}</td>
                <td>{shift.tags.map(tag=><span className='bg-secondary text-white p-2 d-inline-block rounded m-1' key={tag.name}>{tag.name}</span>)}</td>
                <td>{guestToString(shift.regularAssignee)}</td>
                <td className='row-actions'>
                  <Button title="Clone" variant='light' onClick={()=> {setSelectedWeek(props.week); const clone = { ...shift }; delete clone.id; setSelectedShift(clone); setShowAddShiftModal(true);  }}><MdContentCopy /></Button>
                  <Button variant='light' onClick={()=> {setSelectedWeek(props.week); setSelectedShift(shift); setShowAddShiftModal(true);}}><MdMode /></Button> 
                  <Button variant='light' onClick={()=>{setShowDeleteShiftModal(true); setSelectedShift(shift);}}><MdDelete /></Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }

  function TypesConfig(props) {
    return (
      <div className='data-table-container types-table-container'>
        <div className='table-actions table-filters'>
          <div style={{flex: '1 1 0px'}} />
          <Button variant="primary" onClick={()=>{setSelectedShiftType(null); setShowAddShiftTypeModal(true); }}><IoAddOutline /> Create</Button>
        </div>
        <Table striped bordered hover className='types-table data-table'>
          <thead>
            <tr>
              <td>Type</td>
              <td>Open for Signup</td>
              <td className='row-actions'></td>
            </tr>
          </thead>
          <tbody>
              {types && types.map(type=>
                <tr key={type.type}>
                  <td style={{backgroundColor: type.colour}}>{type.type}</td>
                  <td>{type.isOpenForSignup == true ? <IoCheckmarkSharp/> : ''}</td>
                  <td className='row-actions'>
                    <Button variant='light' onClick={()=>{setShowAddShiftTypeModal(true); setSelectedShiftType(type);}}><MdMode /></Button>
                    <Button variant='light' onClick={()=>{setShowDeleteShiftTypeModal(true); setSelectedShiftType(type);}}><MdDelete /></Button>
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </div>
    )
  }

  function PeriodsConfig(props) {
    return (
      <div className='data-table-container periods-table-container'>
        <div className='table-actions table-filters'>
          <div style={{flex: '1 1 0px'}} />
        </div>
        <Table striped bordered hover className='periods-table data-table'>
          <thead>
            <tr>
              <td>Period</td>
              <td>Open</td>
            </tr>
          </thead>
          <tbody>
              {signupPeriods && signupPeriods.map(period=>
                <tr key={period.id}>
                  <td>{dayjs(period.startDate).format('ddd DD MMM')} - {dayjs(period.endDate).subtract(1, 'day').format('ddd DD MMM')}</td>
                  <td>{dayjs(period.openingTime).format('ddd DD MMM HH:mm')} - {dayjs(period.closingTime).format('ddd DD MMM HH:mm')}</td>
                </tr>
              )}
          </tbody>
        </Table>
      </div>
    )
  }

  return (
    <div id='app-container' className="app-container">
      <Nav url="/config"></Nav>
      <div className='page-header'><h1>Config</h1></div>
      <div id='page-container' className="page-container config-page">  
        <Tabs defaultActiveKey="week1" className="mb-3">
          <Tab eventKey="week1" title="Week 1">
            <WeekConfig week="1"/>
          </Tab>
          <Tab eventKey="week2" title="Week 2">
            <WeekConfig week="2" />
          </Tab>
          <Tab eventKey="week3" title="Week 3">
            <WeekConfig week="3"/>
          </Tab>
          <Tab eventKey="week4" title="Week 4">
            <WeekConfig week="4" />
          </Tab>
          <Tab eventKey="names" title="Names">
            <PeopleConfig />
          </Tab>                    
          <Tab eventKey="periods" title="Signup Periods">
            <PeriodsConfig />
          </Tab>                    
          <Tab eventKey="types" title="Shift Types">
            <TypesConfig />
          </Tab>                    
        </Tabs>
      </div>
      {showAddShiftModal && guestsOptions.length>0 ? <AddShiftModal shift={selectedShift} types={typesOptions} tags={tags} week={selectedWeek} guests={guestsOptions} onCancel={()=>setShowAddShiftModal(false)} onSave={()=>fetchShifts()} /> : <span></span>}
      {showDeleteShiftModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedShift.type} ${shiftToString(selectedShift)}</em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteShiftModal(false)} onConfirm={()=>deleteShift(selectedShift.id)} /> : <span></span>}
      {showEditGuestModal ? <EditGuestModal guest={selectedGuest} onCancel={()=>setShowEditGuestModal(false)} onSave={()=>fetchGuests()} /> : <span></span>}
      {showAddShiftTypeModal ? <AddShiftTypeModal type={selectedShiftType} onCancel={()=>setShowAddShiftTypeModal(false)} onSave={()=>fetchShiftTypes()} /> : <span></span>}
      {showDeleteShiftTypeModal ? <ConfirmationModal title={`Are you sure you want to delete <em>${selectedShiftType.type}</em> ?`} body="This is irreversible." onCancel={()=>setShowDeleteShiftTypeModal(false)} onConfirm={()=>deleteShiftType(selectedShiftType.type)} /> : <span></span>}
      <ToastContainer />
    </div>
  )
}